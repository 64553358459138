.message-card-message {
  padding: 0.3rem;
  background: var(--ppt-blue);
  border-radius: 0.3rem;
  color: var(--light-text);
  font-size: 0.9rem;
  font-weight: 600;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

.message-card {
  transition: 0.1s;
  transition-timing-function: ease-out;
  border-radius: 0.3rem;
  backdrop-filter: blur(5px);
}

.message-card:hover {
  backdrop-filter: blur(5px) contrast(1.15);
}

.message-error {
  background: var(--ppt-red);
}

.message-success {
  background: var(--ppt-success);
}
