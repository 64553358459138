#agent-profile-wrapper {
  overflow: hidden;
}

#agent-profile-image {
  width: 100%;
  display: block;
  height: auto;
  object-fit: cover;
}

#cta-edit-mode-input,
#cta2-edit-mode-input {
  display: flex;
  flex-flow: row nowrap;
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
  min-width: 20rem;
  min-height: 2rem;
  padding: 0.2rem;
  background: var(--alt-bg);
  font-family: var(--headline-font);
}

#cta2-edit-mode-input {
  font-size: 1.17em;
  margin-top: 0.2rem;
}
