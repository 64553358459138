#account-save-button {
  border-bottom: 3px solid transparent;
}

#account-save-button:hover {
  border-bottom: 3px solid var(--ppt-success);
}

#special-message {
  position: absolute;
  height: 2rem;
  bottom: -7rem;
  left: 5rem;
  right: 5rem;
  padding: 1rem;
  border: 5px solid var(--ppt-red);
  background: var(--light-bg);
  color: var(--light-text);
  display: flex;
  flex-wrap: row wrap;
  align-items: flex-end;
  justify-content: center;
  z-index: 1;
  border-radius: 1rem;
}
