#listing-sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
}

#create-listing-panel {
  display: grid;
  grid-template-areas:
    "images images images name name name"
    "images images images address1 address1 address1"
    "images images images address2 address2 address2"
    "city city state state zip zip"
    "price price beds beds baths baths"
    "desc desc desc desc desc desc"
    "housestyle housestyle subdivision subdivision keywords keywords"
    "space space space space cancel submit";
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(5, 1fr) 2fr 1fr;
  grid-gap: 1rem;
}

#create-listing-images {
  grid-area: images;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  background: var(--dark-bg);
  color: var(--light-text);
  box-shadow: 0px 0px 13px -3px var(--light-text) inset;
  padding: 0.6rem;
  overflow-x: auto;
}

#create-listing-images::-webkit-scrollbar {
  width: 0.5rem;
}

#create-listing-images::-webkit-scrollbar-track {
  background: var(--light-bg);
  border-radius: 5px;
  opacity: 0.8;
}

#create-listing-images::-webkit-scrollbar-thumb {
  background: var(--ppt-blue);
  border-radius: 5px;
  opacity: 0.8;
}

#create-listing-images::-webkit-scrollbar-thumb:hover {
  background: var(--ppt-red);
}

.create-listing-image {
  height: 5rem;
  border: 0.3rem solid transparent;
  border-radius: 0.2rem;
  margin: 0.5rem;
}

.create-listing-image-selected {
  border: 0.3rem solid #2fa97f;
}

#create-listing-unique-name {
  grid-area: name;
}

#create-listing-unique-name-input {
  width: calc(100% - 2.4rem);
}

#create-listing-address {
  width: 100%;
}
#create-listing-panel :nth-child(3) {
  grid-area: address1;
  width: calc(100% - 2.4rem);
}

#create-listing-address2 {
  grid-area: address2;
}

#create-listing-city {
  grid-area: city;
}

#create-listing-state {
  grid-area: state;
}
#create-listing-zip {
  grid-area: zip;
}

#create-listing-price {
  grid-area: price;
}

#create-listing-beds {
  grid-area: beds;
}

#create-listing-baths {
  grid-area: baths;
}

#create-listing-description {
  grid-area: desc;
}

#create-listing-style {
  grid-area: housestyle;
}

#create-listing-subdivision {
  grid-area: subdivision;
}

#create-listing-keywords {
  grid-area: keywords;
}

#create-listing-clear {
  grid-area: cancel;
}

#create-listing-submit {
  grid-area: submit;
}

.create-listing-action {
  transition: 0.15s;
  border-bottom: 3px solid transparent;
}

.create-listing-action:hover {
  border-bottom: 3px solid var(--ppt-red);
}

.listing-view {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.listing-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem;
}

.listing-card {
  box-shadow: 0px 5px 28px -5px rgba(0, 0, 0, 0.67);
  background: var(--alt-bg);
  color: var(--dark-bg);
  padding: 1.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.listing-card-image {
  height: 10rem;
  transition: 0.3s;
}

.listing-card-image-placeholder {
  height: 10rem;
  width: 13rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #b92b27; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #1565c0,
    #b92b27
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #1565c0,
    #b92b27
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.listing-card-image-placeholder::after {
  color: #fff;
  content: "no images for listing";
}

.listing-card-image:hover {
  transform: scale(1.05);
  box-shadow: 0px 5px 15px -3px var(--dark-bg);
}

.listing-card-info {
  display: grid;
  grid-template-areas:
    "title title title"
    "address address address"
    "price space leads"
    "buttons buttons buttons";
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 2fr 1fr 0fr;
}

.listing-card-title {
  grid-area: title;
}

.listing-card-address {
  grid-area: address;
}
.listing-card-price {
  grid-area: price;
}
.listing-card-leads {
  grid-area: leads;
}

.listing-card-actions {
  box-shadow: 0px 5px 28px -5px rgba(0, 0, 0, 0.67);
  z-index: 1;
  width: 80%;
  background: var(--light-bg);
  transform: translateY(-1rem);
  height: 4rem;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-evenly;
}

.listing-card-action {
  cursor: pointer;
  position: relative;
  background: transparent;
  min-width: 2rem;
  min-height: 2rem;
  border: none;
  margin: 0.2rem;
  padding: 0.2rem;
  color: var(--alt-bg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.2rem;
}

.listing-card-action:hover {
  background: var(--ppt-blue);
  color: var(--alt-bg);
}

.listing-card-action-label {
  border-bottom: none;
}

#zillow-url-container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
}

#zillow-url-input {
  flex: 0 1 auto;
  min-width: 80%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}

#listing-editor {
  width: 94%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#listing-importer {
  display: flex;
  padding: 1rem 0;

  flex-flow: row nowrap;
}

#listing-form-submit {
  color: var(--light-text);
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  border-bottom: 3px solid transparent;
  transition: 0.15s;
}

#listing-form-submit:hover {
  border-bottom: 3px solid var(--ppt-red);
}

.photo-container {
  min-height: 10rem;
  width: 100%;
  margin: 1rem;
}

#google-maps-importer-form :first-child {
  width: calc(100% - 2.4rem);
}

#create-listing-card {
  margin: 2rem;
  padding: 1.4rem;
  transition: 0.1s;
  transition-timing-function: ease-in-out;
  border: 1rem solid transparent;
}
#create-listing-card:hover {
  border: 1rem solid var(--ppt-success);
  box-shadow: inset 0px 5px 10px rgba(0, 0, 0, 0.27);
}
#create-listing-card:hover #create-listing-icon {
  color: var(--ppt-success);
}
#create-listing-icon {
  filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.27));
}
