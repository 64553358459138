#lead-magnet-section {
  width: 94%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.lead-magnet-card {
  min-height: 10rem;
  position: relative;
  z-index: 1;
}

.collapse.lead-magnet-card {
  min-height: 0;
  height: 2rem;
}

.lead-magnet-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  transform-origin: 50% 50%;
  margin: 0.3rem 1rem 0 1rem;
  max-height: 2.5rem;
  padding: 0.2rem 0.2rem 0 0.2rem;
}

.lead-magnet-label {
  background: transparent;
  min-height: 2rem;
  padding: 0.3rem;
  z-index: 1;
  font-size: 0.8rem;
  transform-origin: 50% 50%;
  transform: translate(0.2rem, 0.5rem);
  opacity: 0.85;
  font-weight: 600;
}

.lead-magnet-value {
  line-height: 1.4;
  padding: 0.4rem 0 0 0;
  color: var(--light-text);
  background: transparent;
  transform: translateY(-1.2rem);
}

.lead-magnet-action {
  cursor: pointer;
  background: transparent;
  min-width: 3rem;
  max-width: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  color: var(--light-text);
  padding: 0.2rem;
  margin: 0.2rem;
  border-radius: 0.2rem;
}

.lead-magnet-action:hover {
  background: var(--ppt-blue);
  color: var(--alt-bg);
}

.slide-under-right {
  position: absolute;
  top: 1rem;
  bottom: 1rem;
  right: -4.6rem;
  width: 4rem;
  transition: 0.2s;
  transition-timing-function: ease-in-out;

  background: var(--alt-bg);
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0.3rem;
}

.hide-panel {
  visibility: hidden;
  opacity: 0;
  z-index: 1;
}

.show-panel {
  z-index: 1;
  visibility: visible;
  opacity: 0.9;
}
