.keyword-container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  min-height: 2rem;
  background: var(--alt-bg);
  margin-bottom: 2rem;
}

.keyword-wrapper {
  display: flex;
  flex-flow: row wrap;
  flex: 3 0 auto;
  width: 100%;
}

.keyword-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--ppt-blue);
  color: var(--light-text);
  padding: 0.3rem;
  margin: 0.1rem 0.2rem;
}

.keyword-input {
  font-family: var(--display-font);
  font-size: 1rem;
  background: var(--alt-bg);
  border: 2px solid var(--ppt-blue);
  color: var(--dark-text);
  padding: 0.3rem;
  margin: 0.1rem 0.2rem;
}
