#dashboard {
  box-sizing: border-box;
  padding: 2rem;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas:
    "goals graph graph graph"
    "leads leads feed feed"
    "campaigns campaigns feed feed";
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 2rem;
  grid-column-gap: 2rem;
}

#dashboard-goal {
  grid-area: goals;
}

#dashboard-graph {
  grid-area: graph;
}

#dashboard-leads {
  padding: 1.1rem 0.5rem;
  max-height: 12rem;
  grid-area: leads;
}

#dashboard-feed {
  grid-area: feed;
  min-height: 30rem;
}

#dashboard-campaigns {
  padding: 1.1rem 0.5rem;
  max-height: 12rem;
  grid-area: campaigns;
}

#campaign-list-wrapper,
#lead-list-wrapper {
  overflow-y: auto;
  width: 100%;
  box-sizing: border-box;
  padding-left: 0.4rem;
  padding-bottom: 0.4rem;
  background: var(--med-bg);
}

.dash-list-item {
  box-sizing: border-box;
  width: 100%;
  background: var(--light-bg);
  color: var(--light-text);
  display: block;
  font-size: 1rem;
  min-height: 1.6rem;
  margin-top: 0.4rem;
}

.dash-list-item:nth-child(even) {
  background: var(--light-bg-alt);
}

.dash-list-sub-item {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 9rem;
}
