#create-new-ad {
  border: 3px solid transparent;
}

#create-new-ad:hover {
  color: var(--ppt-success);
  border: 3px solid var(--ppt-success);
}

#create-new-ad:hover .action-button {
  color: var(--ppt-success);
}

.collapse .campaign-card {
  padding: 1rem;
  max-height: 2rem;
  overflow: hidden;
}
