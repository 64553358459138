#signup-input {
  display: grid;
  height: 100%;
  width: 100%;
  margin: 1.5rem;
  grid-template-areas:
    "message message message"
    "firstname lastname phone"
    "company website zip";
  column-gap: 0.5rem;
}

#signup-input .ppt-input {
  min-width: 30%;
}

#signup-message {
  justify-self: center;
  grid-area: message;
}

#signup-email,
#signup-password {
  justify-self: center;
}

#signup-email {
  grid-area: email;
  min-width: 66% !important;
}

#signup-password {
  grid-area: password;
  min-width: 66% !important;
}

#signup-firstname {
  grid-area: firstname;
}

#signup-lastname {
  grid-area: lastname;
}

#signup-phone {
  grid-area: phone;
}

#signup-company {
  grid-area: company;
}

#signup-website {
  grid-area: website;
}

#signup-zipcode {
  grid-area: zip;
}
