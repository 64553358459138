html {
  --text-color-contrast: #eee;
}

.theme-pipeline {
  --primary-brand-color: #0060ad;
  --primary-brand-color-50: #0060adad;

  --secondary-brand-color: #ea1543;
  --accent-color-dark: #111;
  --accent-color-light: #fff;
}

.theme-keller-williams {
  --primary-brand-color: #b40101;
  --primary-brand-color-50: #b40101ad;

  --secondary-brand-color: #990000;
  --accent-color-dark: #000;
  --accent-color-light: #efefde;
}

.theme-century-21 {
  --primary-brand-color: #404041;
  --primary-brand-color-50: #404041ad;

  --secondary-brand-color: #beae89;
  --accent-color-dark: #000;
  --accent-color-light: #fff;
}

.theme-sothebys {
  --primary-brand-color: #002349;
  --primary-brand-color-50: #002349ad;

  --secondary-brand-color: #999999;
  --accent-color-dark: #000;
  --accent-color-light: #fff;
}

.theme-remax {
  --primary-brand-color: #e11b22;
  --primary-brand-color-50: #e11b22ad;

  --secondary-brand-color: #0054a4;
  --accent-color-dark: #000;
  --accent-color-light: #fff;
}

.theme-berkshire {
  --primary-brand-color: #552448;
  --primary-brand-color-50: #552448ad;

  --secondary-brand-color: #eae3d4;
  --accent-color-dark: #000;
  --accent-color-light: #fff;
}

.theme-coldwell {
  --primary-brand-color: #003366;
  --primary-brand-color-50: #003366ad;

  --secondary-brand-color: #003366;
  --accent-color-dark: #000;
  --accent-color-light-50: #fff;
}

.theme-exit-realty {
  --primary-brand-color: #008c9a;
  --primary-brand-color-50: #008c9aad;

  --secondary-brand-color: #98002e;
  --accent-color-dark: #000;
  --accent-color-light: #fff;
}

.theme-weichert {
  --primary-brand-color: #000000;
  --primary-brand-color-50: #000000ad;

  --secondary-brand-color: #f9d71c;
  --accent-color-dark: #000;
  --accent-color-light-50: #fff;
}

.theme-era {
  --primary-brand-color: #e31937;
  --primary-brand-color-50: #e3193add;

  --secondary-brand-color: #273691;
  --accent-color-dark: #000;
  --accent-color-light: #fff;
}

.theme-exp {
  --primary-brand-color: #19469d;
  --primary-brand-color-50: #19469dad;

  --secondary-brand-color: #000000;
  --accent-color-dark: #f5821f;
  --accent-color-light: #f9a81a;
}

.color-panel {
  height: 2rem;
  box-sizing: border-box;
  border: 3px solid var(--secondary-brand-color);
  background: var(--primary-brand-color);
  color: var(--text-color-contrast);
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 1rem;
  margin: 1rem 0 0 0;
}

.theme-title {
  flex: 1 1 auto;
}

.color-swatch-dark,
.color-swatch-light {
  height: 1rem;
  width: 1rem;
}

.color-swatch-dark {
  background: var(--accent-color-dark);
}

.color-swatch-light {
  background: var(--accent-color-light);
}
